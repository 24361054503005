// const stage = process.env.VUE_APP_STAGE;
const serverURL = process.env.VUE_APP_APIG_API_PROD;
const serverDescription = "Production";

const config = {
  openapi: "3.0.1",
  info: {
    title: `tuango-mobile-api`,
    version: "1.0.0",
  },
  servers: [
    {
      url: `${serverURL}mobile-site-data`,
      description: serverDescription,
    },
  ],
  tags: [
    {
      name: "Tags",
      description: "Operations about tags and filters",
    },
    {
      name: "Offers",
      description: "Operations about offers",
    },
  ],
  paths: {
    "/tags": {
      get: {
        summary: "Get list of mobile filters and tags",
        tags: ["Tags"],
        responses: {
          200: {
            description: "Successful operation. Returns filters and tags",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/FiltersResponse",
                },
              },
            },
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
        parameters: [
          {
            name: "Cache-Control",
            in: "header",
            description: "Invalidate cache if set to 'max-age=0'",
            required: false,
            schema: {
              type: "string",
            },
          },
        ],
      },
    },
    "/offers/{lang}/{offerId}": {
      get: {
        summary: "Get offer details by offer ID",
        tags: ["Offers"],
        parameters: [
          {
            name: "lang",
            in: "path",
            required: true,
            description: "Language code (either 'fr' or 'en')",
            schema: {
              type: "string",
              enum: ["fr", "en"],
            },
          },
          {
            name: "offerId",
            in: "path",
            required: true,
            description: "ID of the offer to fetch",
            schema: {
              type: "string",
            },
          },
          {
            name: "Cache-Control",
            in: "header",
            description: "Invalidate cache if set to 'max-age=0'",
            required: false,
            schema: {
              type: "string",
            },
          },
        ],
        responses: {
          200: {
            description: "Successful operation. Returns offer details",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/OfferResponse",
                },
              },
            },
          },
          500: {
            $ref: "#/components/responses/ServerError",
          },
        },
      },
    },
  },
  components: {
    responses: {
      AccessDeniedError: {
        description: "Access token is missing or invalid",
      },
      ServerError: {
        description: "Server error",
      },
    },
    schemas: {
      FiltersResponse: {
        type: "object",
        properties: {
          filters: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Filter",
            },
          },
          ts: {
            type: "integer",
            description: "Timestamp of the response",
            example: 1727100000000,
          },
        },
      },
      Filter: {
        type: "object",
        properties: {
          name: {
            type: "object",
            properties: {
              fr: {
                type: "string",
                example: "Exemple de filtre",
              },
              en: {
                type: "string",
                example: "Sample Filter",
              },
            },
          },
          index: {
            type: "integer",
            example: 0,
          },
          icon: {
            type: "string",
            format: "url",
            example: "https://example.com/sample-icon.jpg",
          },
          tags: {
            type: "array",
            items: {
              type: "string",
            },
            example: ["sample-tag"],
          },
        },
      },
      OfferResponse: {
        type: "object",
        properties: {
          buttonLabel: {
            type: "string",
            example: "View",
          },
          contentType: {
            type: "string",
            example: "Sample Content",
          },
          description: {
            type: "string",
            description: "Offer description",
            example: "<p>Sample offer description...</p>",
          },
          details: {
            type: "string",
            description: "Details of the offer",
            example: "<ul><li>Sample details...</li></ul>",
          },
          imagesMd: {
            type: "array",
            items: {
              type: "string",
              format: "url",
            },
            example: ["https://example.com/sample-md-image1.jpg"],
          },
          imagesLg: {
            type: "array",
            items: {
              type: "string",
              format: "url",
            },
            example: ["https://example.com/sample-lg-image1.jpg"],
          },
          hidePrice: {
            type: "boolean",
            example: false,
          },
          locations: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Location",
            },
          },
          merchantName: {
            type: "string",
            example: "Sample Merchant",
          },
          merchants: {
            type: "array",
            items: {
              type: "string",
            },
            example: ["Sample Merchant Group"],
          },
          offerId: {
            type: "string",
            example: "12345",
          },
          offerType: {
            type: "string",
            example: "Voucher",
          },
          originalPrice: {
            type: "integer",
            example: 5000,
          },
          price: {
            type: "integer",
            example: 2500,
          },
          slug: {
            type: "string",
            example: "sample-offer-slug",
          },
          tags: {
            type: "array",
            items: {
              type: "object",
              properties: {
                slug: {
                  type: "string",
                },
                label: {
                  type: "string",
                },
              },
            },
            example: [{ slug: "sample-tag", label: "Sample Label" }],
          },
          title: {
            type: "string",
            example: "Sample offer title",
          },
          url: {
            type: "string",
            format: "url",
            example: "https://example.com/sample-offer",
          },
          whatYouGet: {
            type: "string",
            example: "<ul><li>Sample what you get...</li></ul>",
          },
          qtySold: {
            type: "integer",
            example: 100,
          },
          reviewsScore: {
            type: "number",
            format: "float",
            example: 4.5,
          },
          reviewsCount: {
            type: "integer",
            example: 300,
          },
          ts: {
            type: "integer",
            description: "Timestamp of the response",
            example: 1726779130821,
          },
        },
      },
      Location: {
        type: "object",
        properties: {
          country: {
            type: "string",
            example: "Sample Country",
          },
          locationName: {
            type: "string",
            example: "Sample Location Name",
          },
          address: {
            type: "string",
            example: "123 Sample St.",
          },
          city: {
            type: "string",
            example: "Sample City",
          },
          postalCode: {
            type: "string",
            example: "S1A 2B3",
          },
          geoloc: {
            type: "object",
            properties: {
              lng: {
                type: "number",
                example: -73.1234,
              },
              lat: {
                type: "number",
                example: 45.1234,
              },
            },
          },
          site: {
            type: "string",
            format: "url",
            example: "https://example.com/contact/",
          },
          phone: {
            type: "string",
            example: "123-456-7890",
          },
        },
      },
    },
  },
};

export default config;
